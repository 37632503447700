<template>
  <section class="main">

  </section>
</template>

<script>
export default {
  name: 'settings',
  data: function () {
    return {

    }
  },
  computed: {

  },
  async mounted() {

  },
  methods: {

  }
}
</script>